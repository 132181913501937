<template>
  <div class="px-0 d-flex align-center align-self-stretch">
    <v-menu
      :transition="false"
      offset-y
      rounded
      right
      content-class="elevation-12 my-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mr-n1"
          style="height:48px"
          dark
          text
          tile
          :ripple="false"
        >
          <v-icon>
            mdi-account-circle
          </v-icon>
          <span class="pl-2 pr-3">
            {{ user.name }}
          </span>
          <span v-if="user.type === 'ac'" class="mr-2">
            <v-sheet
              color="white"
              class="px-2 py-1 rounded-pill font-weight-black"
              style="font-size: 0.81rem;"
            ><span class="primary--text">{{ selectedCS }}</span></v-sheet>
          </span>
          <ConnectionStatusIndicator />
        </v-btn>
      </template>

      <v-list dense>
        <template
          v-if="user.type === 'ac'"
        >
          <v-subheader v-if="user.type === 'ac'" style="height:auto">
            <div class="text-button pl-2 primary--text font-weight-bold">Class Society</div>
          </v-subheader>
          <v-divider v-if="user.type === 'ac'" class="my-1" />
          <v-list-item-group
            :value="selectedCSIndex"
          >
            <v-list-item
              v-for="(cs, i) in user.assignedTo"
              :key="i"
              :ripple="false"
              @click="swapCS(i)"
              color="primary"
            >
              <v-list-item-icon class="mr-3">
                <v-icon class="mr-0" v-if="selectedCSIndex === i">mdi-check-bold</v-icon>
                <v-icon class="mr-0" v-else />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ cs.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>
        <v-divider v-if="user.type === 'ac'" class="my-1" />
        <v-list-item
          :ripple="false"
          @click="logout"
        >
          <v-list-item-icon class="mr-3">
            <v-icon class="mr-0">mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Log out
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style>
button:focus { outline: none; }
</style>

<script>
import { mapActions } from 'vuex'
import ConnectionStatusIndicator from './connection-status-indicator'

export default {
  components: {
    ConnectionStatusIndicator
  },
  props: {
    selected: {
      type: Number,
      default: null
    }
  },
  computed: {
    menuItems () {
      return this.items
    },
    lselected: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('select', value)
      }
    },
    user () {
      return this.$store.state.auth.user
    },
    selectedCSIndex () {
      return this.user.assignedTo
        ? this.$store.state.acUserSelectedCS
        : null
    },
    selectedCS () {
      return this.user.assignedTo.length
        ? this.user.assignedTo[this.selectedCSIndex].value
        : null
    }
  },
  methods: {
    ...mapActions(['setACUserSelectedCS']),
    logout() {
      return this.$store.dispatch('auth/logout')
    },
    swapCS(value) {
      this.setACUserSelectedCS(value)
    }
  }
}
</script>
