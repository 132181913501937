<template>
  <v-app-bar
    app
    clipped-left
    :color="color"
    dark
    dense
    fixed
    flat
  >
    <v-btn
      v-if="navigationLink"
      icon
      depressed
      tile
      :ripple="false"
      @click.stop="$emit('showNavigation')"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-btn
      v-if="homeLink"
      depressed
      icon
      tile
      exact
      exact-active-class="disable-highlight"
      class="mr-3"
      :ripple="false"
      :to="{ path: '/' }"
    >
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-icon
      v-if="!homeLink"
      class="mr-3"
    >
      mdi-text-box
    </v-icon>

    <v-toolbar-title class="d-flex">
      <div>{{ orgName }} - {{ appName }}</div>
      <Breadcrumbs v-if="!title" />
      <div v-else>
        <v-icon
          class="mx-3"
        >
          mdi-arrow-right
        </v-icon>
        {{ title }}
      </div>
      <!-- <portal-target name="navigationBarTitle" /> -->
    </v-toolbar-title>
    <v-spacer />
    <UserMenu />
  </v-app-bar>
</template>

<style>
.disable-highlight::before{
  background-color: transparent !important;
  opacity:0 !important;
  transition-duration:0 !important;
}
</style>

<script>
import UserMenu from './user-menu'
import Breadcrumbs from './breadcrumbs'

export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    navigationLink: {
      type: Boolean,
      default: false
    },
    homeLink: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      value: null
    }
  },
  components: { Breadcrumbs, UserMenu },
  computed: {
    appName () {
      return this.$store.state.appName
    },
    orgName () {
      return this.$store.state.orgName
    }
  }
}
</script>
