<template>
  <div
    class="d-flex align-center"
    v-if="title"
  >
    <v-icon
      class="mx-3"
    >
      mdi-arrow-right
    </v-icon>
    <!-- <v-icon
      class="mx-3"
    >
      {{ icon }}
    </v-icon> -->
    {{ title }}
  </div>
</template>

<script>
export default {
  computed: {
    title () {
      return (this.$route.meta.page || {}).title
    },
    icon () {
      return (this.$route.meta.menu || {}).icon
    }
  }
}
</script>
